import { getConfigList } from './to-be-rembursed-action';
export default [
    {
        id: '@to-be-rembursed',
        reducer: function () { return import('./to-be-rembursed-reducer'); },
        'get:config:list': function () { return getConfigList(); },
    },
    {
        path: '/expense-account-bill',
        ref: '/',
        onload: function () { return import('./view/expense-account-bill'); },
    },
    {
        path: '/expense-account-manager',
        ref: '/',
        onload: function () { return import('./view/checking-manage'); },
    },
    {
        path: '/expense-account-setting',
        ref: '/',
        onload: function () { return import('./view/expense-account-setting'); },
    },
    {
        id: '@checkingDetaillConfirm',
        path: '/checking-detail-confirm',
        exact: true,
        dependencies: ['@common', '@layout5'],
        onload: function () { return import('./view/checking-manage/CheckingConfirmDetail'); },
    },
    {
        point: '@@layers',
        prefix: '@to-be-rembursed',
        onload: function () { return import('./layers'); },
    },
];
