import { app as api } from '@ekuaibao/whispered';
export var configTemplate = function () { return [
    {
        name: 'dynamic-title',
        title: i18n.get('适合单据模板'),
    },
    {
        name: 'specificationIds',
        label: i18n.get('选择适用的报销单据模板'),
        placeholder: i18n.get('请选择选择适用的报销单据模板'),
        type: 'specification-dynamic-item',
        mode: 'multiple',
        optional: false,
        editable: true,
    },
    {
        name: 'dynamic-title',
        title: i18n.get('汇总信息表头设置'),
    },
    {
        name: 'headerSetting',
        optional: true,
        editable: true,
    },
    {
        name: 'dynamic-title',
        title: i18n.get('服务费计算规则 '),
    },
    {
        name: 'serviceSetting',
        optional: true,
        editable: true,
    },
]; };
export var defaultSummaryDim = { dimSource: 'feeType', dimValue: '', moneyField: '' };
export var defaultSummaryConfig = {
    header: '',
    summaryDim: [defaultSummaryDim],
};
export var buttonList = [
    { text: i18n.get('发起对账'), name: 'send_reconciliation', type: 'primary' },
    { text: i18n.get('导出选中'), name: 'export_selected' },
    { text: i18n.get('打印'), name: 'print' },
];
export var allScenes = { text: i18n.get('全部'), scene: 'all', active: true, sceneIndex: 'all' };
var defaultScenes = function () { return ({
    expense: i18n.get('报销单'),
}); };
function getScenes(prefix, type, value) {
    return {
        // @ts-ignore
        text: defaultScenes()[type],
        sceneIndex: type,
        filters: [
            {
                key: prefix + "form.specificationId.originalId.specificationGroupId",
                value: [value],
                includeDep: false,
            },
        ],
        condition: 'or',
    };
}
export function getDefaultScenes(prefix, scenes, specificationGroup) {
    if (!specificationGroup)
        return [];
    var newPrefix = prefix ? prefix + '.' : '';
    var defaultScenes = [];
    scenes.map(function (item) {
        var groupIds = specificationGroup.filter(function (o) { return o.id.indexOf(item) > -1; });
        if (groupIds.length > 0) {
            defaultScenes.push(getScenes(newPrefix, item, groupIds[0].id));
        }
    });
    return defaultScenes;
}
export function getConfigString(config, needEncode) {
    if (needEncode === void 0) { needEncode = true; }
    var includeDetails = config.includeDetails, includeTrips = config.includeTrips, _a = config.fields, fields = _a === void 0 ? [] : _a, exportRefCode = config.exportRefCode, exportAll = config.exportAll, exportTime = config.exportTime, detailsType = config.detailsType;
    fields = fields.map(function (field) { return field.replace(new RegExp(/(flowId.)/g), '').replace(new RegExp(/(form.)/g), ''); });
    var str = JSON.stringify({ includeDetails: includeDetails, includeTrips: includeTrips, fields: fields, exportRefCode: exportRefCode, exportAll: exportAll, exportTime: exportTime, detailsType: detailsType });
    if (needEncode) {
        str = encodeURIComponent(str);
    }
    return str;
}
export function getPropertySet() {
    return api
        .dataLoader('@common.globalFields')
        .load()
        .then(function (resp) { return resp.data; });
}
export var filterType = '/api/flow/v2/filter';
