import { __assign, __awaiter, __generator } from "tslib";
import { app as api, app } from '@ekuaibao/whispered';
import key from './key';
import { Resource } from '@ekuaibao/fetch';
import { Fetch } from '@ekuaibao/fetch';
import parseQuery2Select from '@ekuaibao/lib/lib/parseQuery2Select';
import parseSelectUsePropertySet from '@ekuaibao/lib/lib/parseSelectUsePropertySet';
var agentcyExpenseConfig = new Resource('/api/v1/agentcyexpense/config');
var summaryrelationResource = new Resource('/api/v1/summaryrelation');
import { getLinkNodeElement, triggerClick } from '@ekuaibao/sdk-bridge/sdk/utils';
import moment from 'moment';
import { getConfigString, getPropertySet } from './utils';
var ignoreFields = ['action'];
/**
 * @description 配置信息
 * @returns
 */
export function getConfigList() {
    return agentcyExpenseConfig.GET('/list');
}
/**
 * @description 新建 更新配置
 * @param params
 * @returns
 */
export function saveConfigList(params) {
    return params.originalId ? agentcyExpenseConfig.PUT('/update', params) : agentcyExpenseConfig.POST('/save', params);
}
/**
 * @description 代报销单据 未 中 已
 * @param params
 * @param scene
 * @param dimensionItems
 * @returns
 */
export function getExpenseBillList(params, scene, dimensionItems) {
    return __awaiter(this, void 0, void 0, function () {
        var joinMoney, param, _a, queryString, qs;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    joinMoney = app.require('@lib/entity.join').joinMoney;
                    param = joinMoney('flowId.');
                    return [4 /*yield*/, getParams(params, scene, dimensionItems)];
                case 1:
                    _a = _b.sent(), queryString = _a.queryString, qs = _a.qs;
                    return [2 /*return*/, summaryrelationResource.POST('/list', queryString, __assign(__assign({}, qs), param))];
            }
        });
    });
}
/**
 * @description 发起对账
 * @param relationIds
 * @returns
 */
export function getSendReconciliationResult(relationIds) {
    return {
        type: key.SEND_RECONCILISTION,
        payload: summaryrelationResource.POST('/initiateReconciliation', { relationIds: relationIds }),
    };
}
function handleScence(scene, dimensionItems, prefix) {
    var filtersFixer = app.require('@lib/filtersFixer');
    var sceneFiltersQuery = '', qs = undefined;
    if (!scene) {
        return { sceneFiltersQuery: sceneFiltersQuery, qs: qs };
    }
    if (scene.scene === 'waitInvoice') {
        sceneFiltersQuery = '';
        qs = { waitInvoice: true };
    }
    else {
        sceneFiltersQuery = filtersFixer(scene, prefix, dimensionItems);
    }
    return { sceneFiltersQuery: sceneFiltersQuery, qs: qs };
}
/**
 * @description 列表 导出 获取传参
 * @param params
 * @param scene
 * @param dimensionItems
 * @returns
 */
function getParams(params, scene, dimensionItems) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, getPropertySet().then(function (propertySet) {
                    var query = parseQuery2Select(params);
                    var content = parseSelectUsePropertySet(propertySet);
                    var _a = handleScence(scene, dimensionItems), sceneFiltersQuery = _a.sceneFiltersQuery, qs = _a.qs;
                    query
                        .asc('updateTime')
                        .select("flowId(flowRulePerformLogs(...),ownerId(id,name),plan(...),calcRiskWarning(...),form(" + content + ",...),...),...")
                        .filterBy(sceneFiltersQuery);
                    return { queryString: query.value(), qs: qs };
                })];
        });
    });
}
function getSelectFields(bus) {
    return __awaiter(this, void 0, void 0, function () {
        var fields, newFields;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!bus) {
                        return [2 /*return*/, []];
                    }
                    return [4 /*yield*/, bus.invoke('get:column:checked:value')];
                case 1:
                    fields = _a.sent();
                    newFields = fields.map(function (item) {
                        if ('form.linkRequisitionInfo.name' === item) {
                            return 'form.linkRequisitionInfo';
                        }
                        else if ('form.expenseLink.name' === item) {
                            return 'form.expenseLink';
                        }
                        else if ('flowId.form.linkRequisitionInfo.name' === item) {
                            return 'flowId.form.linkRequisitionInfo';
                        }
                        else if ('flowId.form.expenseLink.name' === item) {
                            return 'flowId.form.expenseLink';
                        }
                        else {
                            return item;
                        }
                    });
                    return [2 /*return*/, newFields.filter(function (f) { return !~ignoreFields.indexOf(f); })];
            }
        });
    });
}
/**
 * @description 导出全部数据
 * @param params
 * @param scene
 * @param dimensionItems
 * @returns
 */
export function exportExcelAll(params, scene, dimensionItems, bus) {
    return __awaiter(this, void 0, void 0, function () {
        var joinMoney, param, _a, queryString, qs, c, _b, config;
        var _this = this;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    joinMoney = app.require('@lib/entity.join').joinMoney;
                    param = joinMoney('flowId.');
                    return [4 /*yield*/, getParams(params, scene, dimensionItems)];
                case 1:
                    _a = _c.sent(), queryString = _a.queryString, qs = _a.qs;
                    delete queryString.limit;
                    delete queryString.orderBy;
                    return [4 /*yield*/, api.open('@layout:ExportExcelModal')];
                case 2:
                    c = (_c.sent());
                    if (!!(c === null || c === void 0 ? void 0 : c.exportAll)) return [3 /*break*/, 4];
                    _b = c;
                    return [4 /*yield*/, getSelectFields(bus)];
                case 3:
                    _b.fields = _c.sent();
                    _c.label = 4;
                case 4:
                    config = getConfigString(c, false);
                    return [2 /*return*/, summaryrelationResource.POST('/exportWay', queryString, __assign(__assign({}, qs), param)).then(function (res) { return __awaiter(_this, void 0, void 0, function () {
                            var exportWay, blob, taskName;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        exportWay = res.value.exportWay;
                                        if (!(exportWay === 'sync')) return [3 /*break*/, 2];
                                        return [4 /*yield*/, Fetch.POST("/api/v1/summaryrelation/summaryRelationExport/$xlsx?config=" + config, {}, { body: queryString, headers: { accept: 'application/octet-stream' }, isBlob: true })];
                                    case 1:
                                        blob = _a.sent();
                                        return [2 /*return*/, downloadExcel(blob)];
                                    case 2:
                                        if (!(exportWay === 'async')) return [3 /*break*/, 4];
                                        queryString.xlsx = 'xlsx';
                                        return [4 /*yield*/, api.open('@layout:AsyncExportModal')];
                                    case 3:
                                        taskName = (_a.sent()).taskName;
                                        return [2 /*return*/, summaryrelationResource.POST("/summaryRelationExport/$xlsx/async?taskName=" + taskName + "&config=" + config, queryString, __assign(__assign({}, qs), param))];
                                    case 4: return [2 /*return*/];
                                }
                            });
                        }); })];
            }
        });
    });
}
/**
 * @description 同步post模拟点击事件
 * @param blob
 */
var downloadExcel = function (blob) {
    var date = new Date();
    var el = getLinkNodeElement();
    var url = window.URL.createObjectURL(blob);
    var filename = "\u5355\u636E_" + moment(date).format('YYYYMMDD') + "_" + moment(date).format('hhmm') + ".xlsx";
    var name = decodeURIComponent(filename);
    el.setAttribute('href', url);
    el.setAttribute('download', name);
    triggerClick(el);
};
